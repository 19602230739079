<template>

    <div class=" d-flex justify-content-center">
        <template v-for="(category, name) in datax">
            <span class="border my-5 mx-5 shadow-sm">
                <div class="card-header">
                    <h5 class="my-2 mx-2 text-center text-capitalize ">{{ name }}</h5>
                </div>
                <div class="p-2 bd-highlight">
                    <div v-for="(child,name) in category" class="card shadow-sm my-3">
                        <div class="card-header text-capitalize">
                            <h5 class="my-0">{{ name }}</h5>
                        </div>
                        <!--                        <div class="container text-center justify-content-center">-->
                            <div class="card text-center justify-content-center" v-for="address in child">
                                <div class="card-header">{{ address.address }}</div>
                                <div class="card-body" v-if="datacollection.hasOwnProperty(address.id)">
                                    <div class="row justify-content-center">
                                        Status code:
                                        <p v-if="inRange(status_code[address.id].data,200,299)" style="color:green;"> {{status_code[address.id].data}}</p>
                                        <p v-if="inRange(status_code[address.id].data,300,399)" style="color:goldenRod;"> {{status_code[address.id].data}}</p>
                                        <p v-if="inRange(status_code[address.id].data,400,499)" style="color:red;"> {{status_code[address.id].data}}</p>
                                        <p v-if="inRange(status_code[address.id].data,500,599)" style="color:red;"> {{status_code[address.id].data}}</p>
                                        <p v-if="status_code[address.id].data == 0" style="color:red;"> {{status_code[address.id].data}}</p>
                                    </div>
                                    <div class="row justify-content-center">
                                        <div v-if="address.ping" class="col-6">
                                            <line-chart
                                                :chart-data="datacollection[address.id].Ping"
                                                :options="ping_options"></line-chart>
                                        </div>
                                        <div v-if="address.http" class="col-6">
                                            <line-chart
                                                :chart-data="datacollection[address.id].BandWidth"
                                                :options="bw_options"></line-chart>
                                        </div>
                                    </div>


                                </div>
                                <!--                            </div>-->
                        </div>
                    </div>
                </div>
            </span>
        </template>
    </div>
</template>

<script>

import Chart from 'chart.js';
import LineChart from './LineChart.js'
import Vue from 'vue';


export default {
    name: "index",
    props: ['datax'],
    components: {LineChart},
    data: () => {
        return {
            gathered_data1: {},
            gathered_data2: {},
            datacollection: {},
            address_data: {},
            status_code: 0,
            chart: {},
            ping_options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            suggestedMin: 0,
                            suggestedMax: 500
                        }
                    }]
                },
                responsive: true,
            },
            bw_options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            suggestedMin: 0,
                            suggestedMax: 1000
                        }
                    }]
                },
                responsive: true,
            },
            sc_options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            suggestedMin: 200,
                            suggestedMax: 600
                        }
                    }]
                },
                responsive: true,
            },
            labels: [
                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
            ]
        }
    },
    mounted() {

        console.log('Component mounted.');
        this.initAddresses();
        this.getPing();
        this.getBandWidth();
        this.getStatusCode();
        this.setIntervals();
    },

    methods: {

        inRange(x, min, max) {
            return x >= min && x <= max;
        },

        getLastStatus(array) {
            if (Array.isArray(array)) {
                var len = array.length - 1;
                if (len >= 0) {
                    return array[len];
                }
            }
            return 0;
        },

        fillDataPing(gatheredData) {
            for (const [id, data] of Object.entries(gatheredData)) {
                let dataset = {
                    labels: data.label,
                    datasets: [
                        {
                            label: 'Ping',
                            backgroundColor: '#f87979',
                            fill: false,
                            data: data.data,
                        },
                    ],

                };
                if (this.datacollection.hasOwnProperty(id)) {
                    Vue.set(this.datacollection[id], 'Ping', dataset);
                } else {
                    Vue.set(this.datacollection, id, {Ping: dataset});
                }
            }

        },

        fillDataBandWidth(gatheredData) {
            for (const [id, data] of Object.entries(gatheredData)) {
                let dataset = {
                    labels: data.label,
                    datasets: [
                        {
                            label: 'BandWidth',
                            backgroundColor: '#f87979',
                            fill: false,
                            data: data.data,
                        },
                    ]
                };
                if (this.datacollection.hasOwnProperty(id)) {
                    Vue.set(this.datacollection[id], 'BandWidth', dataset);
                } else {
                    Vue.set(this.datacollection, id, {BandWidth: dataset});
                }
            }

        },

        fillDataStatusCode(gatheredData) {
            for (const [id, data] of Object.entries(gatheredData)) {
                let dataset = {
                    labels: data.label,
                    datasets: [
                        {
                            label: 'StatusCode',
                            backgroundColor: '#f87979',
                            fill: false,
                            steppedLine: true,
                            data: data.data,
                        },
                    ],
                };
                if (this.datacollection.hasOwnProperty(id)) {
                    Vue.set(this.datacollection[id], 'StatusCode', dataset);
                } else {
                    Vue.set(this.datacollection, id, {StatusCode: dataset});
                }
            }

        },

        initAddresses() {
            for (const [name, mother] of Object.entries(this.datax)) {
                for (const [name, category] of Object.entries(mother)) {
                    for (const adr of category) {
                        let options = {
                            title: {
                                display: true,
                                text: adr.name
                            },
                            responsive: true,
                        };
                        let data = {
                            ping: [],
                            bw: [],

                        }
                        Vue.set(this.address_data, adr.id, data);
                    }
                }
            }
        },

        getPing() {
            console.log('Ping');
            axios.get('/api/get_data/1').then(response => {
                this.gathered_data1 = response.data;
                this.fillDataPing(response.data);
            });

        }
        ,

        getBandWidth() {
            console.log('BandWidth');
            axios.get('/api/get_data/2').then(response => {
                this.gathered_data2 = response.data;
                this.fillDataBandWidth(response.data);
            });
        },

        getStatusCode() {
            // console.log('StatusCode');
            // axios.get('/api/get_data/3').then(response => {
            //     this.gathered_data2 = response.data;
            //     this.fillDataStatusCode(response.data);
            // });
            console.log('StatusCode');
            axios.get('/api/get_data/3').then(response => {
                this.status_code = response.data;
            });
        },

        setIntervals() {
            setInterval(this.getPing, 30000);
            setInterval(this.getBandWidth, 30000);
            setInterval(this.getStatusCode, 30000);
        }
    }
}

</script>

<style>

</style>
